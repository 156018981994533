.Home {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding-top: 5vh;
}

.Home::-webkit-scrollbar {
  display: none;
}

h1 {
  color:#2952a3;
}

p {
  font-size: 17px;
}

img {
  max-width: 60vw;
  max-height: 75vh;
}

button {
  background-color: #2952a3;
  border: none;
  color: white;
  font: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

button:hover {
  background-color: lightgray;
  color:black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: circle;
  color: #2952a3;
  font-size: large;
}

.App {
  text-align: left;
  color: #2952a3;
}

.Site-header {
  background-color: white;
  width: 100vw;
  height: 12vh;
  border-bottom: 5px #2952a3;
  position: fixed;
  z-index: 999;
  top:0
}

.Site-header > img {
  max-height: 100px;
  height: 100%;
  width: 25vw;
  min-width: 250px; 
}

.Site-header > button {
  height: 100%
}

.Site-footer {
  background-color: #DCDCDD;
  font-size: medium;
  color: #5D5D5D;
  height: 5vh;
}

.Default-button {
  font-weight: 500;
  align-self: center;
}

.job-cards {
  padding: 15px;
  margin: 15px;
  margin-left: 5vw;
  margin-right: 5vw;
}

.activeSlide {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: '5px';
}

.carousel {
  width: 100vw;
  height: 90vh;
}

.carousel > button {
  border: none;
  color: #212121d3;
  font-size: x-large;
  height: 100%;
  background-color: transparent;
}

.carousel > button:hover {
  background-color: lightgrey;
  opacity: 60%;
}

@media (max-width: 768px) {

  .menuItem {
    display: none !important;
  }

  .Site-header {
    padding-top: 2.5vh;
    height: 12vh;
  }
  
  .Site-header > img {
    height: 100%;
    width: 25vw;
    min-width: 250px; 
  }
}
